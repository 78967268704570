import React, { useEffect, useRef, useState } from 'react';
import { Authenticator, Button, Icon, Image, Text, View, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
import { strings } from './strings';
import components from './components';
import logo from "./assets/Logos/effe-logo.svg"
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchAuthSession, signIn, signUp, signOut } from 'aws-amplify/auth';
import awsconfig from "./aws-exports";

I18n.putVocabularies({ ...translations, ...strings });

const App = () => {
	const navigate = useNavigate();
	const location = useLocation();
	let [searchParams, setSearchParams] = useSearchParams();

	const [redirectUri, setRedirectUri] = useState();
	const refLoginManually = useRef();
	const [continueAsLink, setContinueAsLink] = useState();
	const getCurrentLocale = () => navigator.language.substr(0, 2) || "it";
	const loadDefaultLanguage = () => {
		const navLang = getCurrentLocale();
		I18n.setLanguage(["en", "it", "es", "fr", "de"].includes(navLang) ? navLang : 'it');
	}

	const { route, toSignUp } = useAuthenticator(context => [context.route]);

	const isStudioRequiredForRole = (role) => {
		return ["Architetto/Designer", "Ingegnere/Progettista", "Gestore Hotel/SPA/Gym",
			"Contractor", "Rivenditore", "Agenzia", "Centro Assistenza/Installatore"
		].includes(role)
	}
	/**
	 * Questo handle è utilizzato per fixare il comportamento anomale della Tab dell'Authenticator
	 */
	useEffect(() => {
		const disableOnTabChange = (event) => {
			const isAlreadySelected = event.target?.getAttribute("aria-selected") == "true";
			if (isAlreadySelected && (event.target.matches('#signUp-tab') || event.target.matches('#signIn-tab'))) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				return;
			}
		};
		document.addEventListener('click', disableOnTabChange, true);
		return () => {
			document.removeEventListener('click', disableOnTabChange, true);
		};
	}, []);

	/**
	 * Mostra/visualizza campi condizionali
	 */
	useEffect(() => {
		const handleVisibilitySelects = (event) => {
			const valueSelect = event.target?.value;
			if (!!valueSelect && !isStudioRequiredForRole(valueSelect)) {
				document.querySelector("input[name='custom:effe_company']").style.display = "none";
			} else {
				document.querySelector("input[name='custom:effe_company']").style.display = "block";
			}
		};
		document.addEventListener('change', handleVisibilitySelects, true);
		return () => {
			document.removeEventListener('change', handleVisibilitySelects, true);
		};
	}, []);

	useEffect(() => {
		const checkAuthState = async () => {
			const queryParams = new URLSearchParams(location.search);
			const force_logout = queryParams.get('force_logout') == "true";
			if (force_logout) {
				await signOut({ global: true })
			}
			const session = await fetchAuthSession();
			const redirect_uri = queryParams.get('redirect_uri');
			const from_window = queryParams.get('from_window') == "true";
			// Fa redirect alla Registrazione
			const to_signup = queryParams.get('to_signup') == "true";
			const version = queryParams.get('version');
			const id_token = session?.tokens?.idToken.toString();
			const access_token = session?.tokens?.accessToken?.toString();
			const email = session?.tokens?.idToken.payload?.email;
			const username = session?.tokens?.idToken.payload?.["cognito:username"];

			const refresh_token = localStorage.getItem(
				`CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.${email}.refreshToken`
			);

			console.log(`checkAuthState: ${JSON.stringify({ route })}`)

			try {
				//Switch to signup
				if (to_signup && route == "setup" && !id_token) {
					toSignUp();
				}
				else if (redirect_uri && id_token && route === "authenticated") {
					const link_redirect = `${redirect_uri}?id_token=${id_token}`;
					if (refLoginManually?.current == true) {
						setRedirectUri(redirect_uri);
						setTimeout(async () => {
							window.location.replace(link_redirect);
						}, 1500);
					} else {
						setContinueAsLink(link_redirect)
					}
				} else if (!redirect_uri && from_window && id_token && route === "authenticated" && email && username && access_token) {
					/**
					 * Url di callback per l'applicazione
					 */
					const link_redirect = `/callback-mobile-app?${version == "v2" ? `refresh_token=${refresh_token}` : `id_token=${id_token}`}&access_token=${access_token}&email=${email}&username=${username}`;
					if (refLoginManually?.current == true) {
						window.location.replace(link_redirect)
					} else {
						setContinueAsLink(link_redirect)
					}
				}
			} catch (err) {
				//TODO: Show error
				console.log("errr", err)
			}
		};

		checkAuthState();
	}, [location, navigate, route]);


	useEffect(() => {
		loadDefaultLanguage();
	}, [])


	const services = {
		async handleSignIn({ username, password }) {
			refLoginManually.current = true;
			return signIn({
				username,
				password,
				options: {
					authFlowType: 'USER_PASSWORD_AUTH'
				}
			});
		},
		async handleSignUp(input) {
			console.log({ input })
			const { username, password, options } = input;
			const customUsername = username.toLowerCase();
			const customEmail = options?.userAttributes?.email.toLowerCase();
			const { userAttributes } = options;

			let payloadSignUp = {
				username: customUsername,
				password,
				options: {
					...options,
					userAttributes: {
						...userAttributes,
						email: customEmail,
						locale: getCurrentLocale(),
						phone_number: !!userAttributes?.phone_number && userAttributes?.phone_number != undefined ? `${userAttributes?.["custom:dial_code_phone_number"]}${userAttributes?.phone_number?.replace("undefined", "")}` : "",
						["custom:accept_privacy"]: userAttributes?.["custom:accept_privacy"] === "yes" ? "true" : "false"
					},
				},
			};
			delete payloadSignUp?.options?.userAttributes?.["custom:dial_code_phone_number"]
			console.log({ payloadSignUp })
			return signUp(payloadSignUp);
		},
		/** Validazione dei custom fields nella registrazione */
		async validateCustomSignUp(formData, value, third) {
			console.log({ formData, value, third })

			if (!formData["custom:accept_privacy"]) {
				return {
					["custom:accept_privacy"]: I18n.get("Error_Privacy"),
				};
			}
			if (!formData["custom:effe_profile"]) {
				return {
					["custom:effe_profile"]: I18n.get("Error_Profile"),
				};
			}
			if (isStudioRequiredForRole(formData["custom:effe_profile"]) && !formData["custom:effe_company"]) {
				return {
					["custom:effe_company"]: I18n.get("Error_Company_Studio"),
				};
			}
			if (!formData["custom:country"]) {
				return {
					["custom:country"]: I18n.get("Error_Country"),
				};
			}
			if (!formData.password) {
				return {
					password: I18n.get("Error_Password"),
				};
			}
			if (!formData.confirmpassword) {
				return {
					confirmpassword: I18n.get("Error_ConfirmPassword"),
				};
			}
			if (formData.confirmpassword !== formData.password) {
				return {
					confirmpassword: I18n.get("Error_ConfirmPasswordMatch"),
				};
			}
			if (!formData.email) {
				return {
					email: I18n.get("Error_Email"),
				};
			}
			if (!formData.given_name) {
				return {
					given_name: I18n.get("Error_Firstname"),
				};
			}
			if (!formData.family_name) {
				return {
					given_name: I18n.get("Error_Surname"),
				};
			}
		}
	};

	return (
		<div>
			<Authenticator services={services} components={components}>
				{({ signOut, user }) => {
					return (
						<div style={{ display: "flex", flexDirection: "column" }}>
							<View textAlign="center">
								<Image
									alt="Effe logo"
									src={logo}
									style={{ maxWidth: 150 }}
								/>
							</View>
							{

								[
									<Text style={{ marginBottom: 20 }}>
										{redirectUri ? `${I18n.get("RedirectToWebsite")}: ${redirectUri}` : null}
										{
											continueAsLink ?
												<button
													style={{
														width: "fit-content", margin: "0 auto", display: "inline-flex",
														fontSize: 16, maxWidth: 400, alignItems: "center",
														outline: "none", background: "#0B0C0C", color: "#00FFA8",
														borderRadius: 25, padding: "12px 25px", cursor: "pointer"
													}}

													onClick={() => {
														window.location.replace(continueAsLink)
													}}>
													<div style={{ display: "flex", alignItems: "center" }}>
														<span>{`${I18n.get("Continue As")} ${user.signInDetails?.loginId}`}</span>
														<svg style={{ scale: "0.8" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00FFA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
															<polyline points="9 18 15 12 9 6"></polyline>
														</svg>
													</div>
												</button>
												: null
										}
									</Text>,
									<Button style={{ width: "fit-content", margin: "0 auto" }} onClick={() => {
										setContinueAsLink(undefined);
										signOut()
									}}>Logout</Button>
								]
							}

						</div>
					)
				}}
			</Authenticator>
		</div>
	);
}

export default App;
